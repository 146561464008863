<template>

    <button :class="{ active: active }"
            :title="labels.scrollToTopText"
            v-html="labels.scrollToTopIcon"
            @click="handleScrollTopClick"></button>

</template>


<script>

import AOS from 'aos';

export default {

    name: 'ScrollToTop',
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            active: false,
            labels: {
                scrollToTopIcon: '<span class="icon"><img src="' + require('../../assets/images/sherpa-speak/ss_arrow-up.webp') + '" alt="" /></span>',
                scrollToTopText: 'Scroll to Top'
            }
        };
    },
    
    created() {

        this.$eventBus.$on('scroll::top', this.handleScrollTopEvent);

    },
    
    mounted() {
        
    },
    
    methods: {

        /**
         * @method handleScrollTopEvent
         * @description `scroll::top` event handler.
         * @param {Object} evt
         */
        handleScrollTopEvent(evt) {
            this.active = (evt && evt.state) ? evt.state : false;
        },

        /**
         * @method handleScrollTopClick
         * @description Scroll to top click event handler.
         */
        handleScrollTopClick() {

            AOS.refresh(); // eslint-disable-line no-undef

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            this.$nextTick(() => {
                this.$el.blur();
            });

        }

    }
    
};

</script>


<style lang="scss" scoped>

button {
    background: linear-gradient(to right, $sherpa-button-gradient-start 0%, $sherpa-button-gradient-end 100%);
    background-clip: padding-box;
    color: $white-100;
    width: calc(45px + 0.5rem);
    height: calc(45px + 0.5rem);
    padding: 1rem;
    border: 0rem solid transparent;
    border-radius: 100%;
    box-shadow: 0 5px 16px $black-20;
    outline: none;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 2rem;
    z-index: 999;
    user-select: none;
    transform: translateX(calc(100% + 1rem));
    transition: all 0.6s $easeOutQuad;

    @media(hover: hover) {
        &:hover {
            cursor: pointer;

            &::after {
                opacity: 1;
                transition: all 0.6s $easeOutQuad;
            }

            & ::v-deep .icon {
                transform: translateY(-3px);
                transition: all 0.6s $easeOutBack;
            }
        }
    }

    &::after {
        content: '';
        background: linear-gradient(to right, $sherpa-button-gradient-start 30%, $sherpa-button-gradient-end 100%);
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: all 0.6s $easeOutQuad;
    }

    &:focus {
        border: 0.25rem solid rgba(64,92,155, 0.5);
        transition: all 0.6s $easeOutQuad;
    }

    &.active {
        transform: translateX(-1rem);
        transition: all 1s $easeOutBack;
    }

    & ::v-deep .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        z-index: 5;
        transition: all 0.6s $easeOutBack;

        & > img {
            width: auto;
            max-height: 100%;
        }
    }
}

</style>
