<template>

    <section data-group="phrases">

        <section-meta :title="title"
                      :description="description"
                      :hrule="true"></section-meta>

        <div class="translation-cards">
            <translation-card v-for="(sound, idx) in sounds"
                              :entry="sound"
                              :status="playedSound(sound.slug)"
                              :key="'sound_' + idx"
                              :data-aos="(idx % 2 === 0) ? 'fade-right' : 'fade-left'"
                              data-aos-offset="20"
                              data-aos-duration="600"
                              data-aos-delay="100"
                              data-aos-easing="ease-out-back"></translation-card>
        </div>
        
    </section>

</template>


<script>

import { mapGetters } from 'vuex';

import SectionMeta from './SectionMeta';
import TranslationCard from './TranslationCard';

export default {

    name: 'SectionPhrases',

    components: {
        SectionMeta,
        TranslationCard
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            title: 'Learn Useful Words and Phrases',
            description: '<p>The Sherpa language is a spoken language derived from Tibetan and is entirely based on the practice of the people speaking it. It has no written script, and that is why the language has evolved over time from place to place within Nepal.</p><p><strong>If you can master these words and phrases, most Sherpa in the Everest region should be able to understand you. </strong></p>',
            sounds: [],
            group: 'phrases'
        };
    },

    computed: {
        ...mapGetters('SherpaSpeak', {
            soundsByGroup: 'soundsByGroup',
            playedSound: 'playedBySlug'
        })
    },
    
    created() {
        
    },
    
    mounted() {
        this.sounds = this.soundsByGroup(this.group);
    },
    
    methods: {
        
    }
    
};

</script>


<style lang="scss" scoped>

section {
    margin: 3rem 0 0 0;
    position: relative;
    z-index: 2;

    & > .inner {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.translation-cards {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

</style>
