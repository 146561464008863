<template>

    <section class="section-mastery"
             v-if="ready"
             data-aos="zoom-out-up"
             data-aos-duration="2600"
             data-aos-delay="0"
             data-aos-easing="ease-out-quad">

        <div class="inner" :class="'translation-' + sounds[0].slug">

            <section-meta :title="title"
                          :description="description"
                          :hrule="true"
                          :aosAnchorClass="'.translation-' + sounds[0].slug"></section-meta>

            <div class="translation-cards" v-if="sounds.length">

                <div class="language english">
                    <div class="phrase"
                         data-aos="zoom-in-right"
                         :data-aos-anchor="'.translation-' + sounds[0].slug"
                         data-offset="20"
                         data-aos-duration="800"
                         data-aos-delay="1400"
                         data-aos-easing="ease-out-back">
                        <span v-html="sounds[0].phrase.english"></span>
                    </div>
                </div>

                <div class="language tibetan">
                    <button class="phrase"
                            :data-slug="sounds[0].slug"
                            @click.capture.stop.prevent="handlePhraseClick"
                            data-aos="zoom-in-left"
                            :data-aos-anchor="'.translation-' + sounds[0].slug"
                            data-aos-duration="800"
                            data-aos-delay="2400"
                            data-aos-easing="ease-out-back">
                        <span v-html="stateIcon + ' ' + sounds[0].phrase.tibetan"></span>
                        <audio-player :id="sounds[0].slug"
                                      :source="sounds[0].audio.tibetan"
                                      :slug="sounds[0].slug"></audio-player>
                    </button>
                </div>

            </div>

        </div>

        <mastery-bg-scene :aosAnchor="'.translation-' + sounds[0].slug"></mastery-bg-scene>
        
    </section>

</template>


<script>

import { mapGetters } from 'vuex';

import SectionMeta from './SectionMeta';
import AudioPlayer from './AudioPlayer';
import MasteryBgScene from './MasteryBgScene';

export default {

    name: 'SectionMastery',

    props: {
        state: {
            type: String,
            default: 'ready'
        }
    },

    components: {
        SectionMeta,
        AudioPlayer,
        MasteryBgScene
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            title: 'Have You Mastered the Language?',
            description: '<p>Learn the most useful phrase in preparation for your journey.</p>',
            sounds: [],
            group: 'mastery',
            ready: false
        };
    },

    computed: {

        stateIcon() {
            return '<span class="icon ' + this.state + '"></span>';
        },

        ...mapGetters('SherpaSpeak', {
            soundsByGroup: 'soundsByGroup',
            playedSound: 'playedBySlug'
        })

    },
    
    created() {
        
    },
    
    mounted() {
        this.sounds = this.soundsByGroup(this.group);

        this.$nextTick(() => {
            this.ready = true;
        });
    },
    
    methods: {
        /**
         * @method handlePhraseClick
         * @description
         * @param {Object} evt
         */
        handlePhraseClick(evt) {
            if (evt && evt.target && evt.target.childNodes[1] && (typeof evt.target.childNodes[1] === 'object')) {
                evt.target.childNodes[1].play();
            }
        }
    }
    
};

</script>


<style lang="scss" scoped>

section {
    margin: 3rem -1rem 0 -1rem;
    padding: 0 0 3rem 0;
    position: relative;
    z-index: 1;

    &::after {
        content: '';
        background-image: url('../../assets/images/sherpa-speak/ss_mastery-bg.webp'),
                          linear-gradient(0deg, rgba(255,255,255,1) 64%, rgba(236,244,250,1) 100%);
        background-size: 1194px 988px, auto auto;
        background-position: 50% bottom, 50% bottom;
        background-repeat: no-repeat, no-repeat;
        width: 100%;
        height: auto;
        min-height: 988px;
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: -1;
        transform: translateX(-50%);
    }

    & > .inner {
        width: 100%;
        max-width: 1194px;
        height: 100%;
        margin: 0 auto;
        padding: 0 0 16.250rem 0;
        position: relative;
        z-index: 2;
    }

}


.translation-cards {
    width: 100%;
    max-width: calc(700px + 2rem);
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    @media (min-width: 576px) { // RED (SM)

    }
    @media (min-width: 768px) { // GREEN (MD)
        max-width: calc(600px + 2rem);
    }
    @media (min-width: 992px) { // BLUE (LG)
        max-width: calc(700px + 2rem);
    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    & .language {
        flex: 1 0 50%;

        &.english {
            align-self: center;

            @media (min-width: 576px) { // RED (SM)

            }
            @media (min-width: 768px) { // GREEN (MD)
                align-self: flex-start;
            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & .phrase {
                background-color: $sherpa-bubble-bg-color;
                color: $sherpa-light-blue;
                border: 2px solid $sherpa-light-blue;
                border-radius: 24px 24px 24px 0;
            }
        }

        &.tibetan {
            align-self: center;

            @media (min-width: 576px) { // RED (SM)

            }
            @media (min-width: 768px) { // GREEN (MD)
                align-self: flex-end;
            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & .phrase {
                background: linear-gradient(69deg, $sherpa-orange-gradient-start 0%, $sherpa-orange-gradient-end 120%),
                linear-gradient(to right, $sherpa-orange 0%, $sherpa-orange 100%);
                color: $white-100;
                border: 2px solid $sherpa-orange;
                border-radius: 24px 24px 0 24px;

                @media(hover: hover) {
                    &:hover {
                        cursor: pointer;
                    }
                }

                &:focus {

                    &::after {
                        background-color: $sherpa-focus-color-orange;
                        width: calc(100% + 1rem);
                        height: calc(100% + 1rem);
                        transition: all 0.3s $easeOutQuad;
                    }
                }

                &::after {
                    content: '';
                    background-color: transparent;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px 30px 0 30px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    transform: translateX(-50%) translateY(-50%);
                    transition: all 0.3s $easeOutQuad;
                }

                &.playing {

                    &::after {
                        background-color: $sherpa-focus-color-orange;
                        width: calc(100% + 1rem);
                        height: calc(100% + 1rem);
                        transition: all 0.3s $easeOutQuad;
                    }
                }
            }
        }

        & h4 {
            font-size: 0.813rem;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            margin: 0 0 1rem 0;
            user-select: none;
        }

        & button {

            &.phrase {
                padding: 0.813rem 0.75rem;
            }
        }

        & .phrase {
            padding: 1rem 0.75rem;
            border: 0 none;
            outline: 0 none;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            user-select: none;

            & > span {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
            }

            & > * {
                pointer-events: none;
            }

            & ::v-deep .icon {
                background-position: 50% 50%;
                background-size: contain;
                background-repeat: no-repeat;
                width: 30px;
                height: 30px;
                display: block;

                &.ready {
                    background-image: url('../../assets/images/sherpa-speak/ss_audio-speaker.webp');
                }

                &.playing {
                    background-image: url('../../assets/images/sherpa-speak/ss_audio-bars.webp') !important;
                }
            }
        }
    }
}

</style>
