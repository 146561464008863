<template>

    <div class="pixel-trigger"></div>

</template>


<script>

export default {

    name: 'PixelTrigger',
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },
    
    created() {
        
    },
    
    mounted() {
        this.observePixel();
    },
    
    methods: {

        /**
         * @method observePixel
         * @description Observe pixel position for triggering `ScrollToTop` component display.
         */
        observePixel() {
            let observer = new IntersectionObserver(entries => {
                if (entries[0].boundingClientRect.y < 0) {
                    this.$eventBus.$emit('scroll::top', { state: true });
                } else {
                    this.$eventBus.$emit('scroll::top', { state: false });
                }
            });

            observer.observe(document.querySelector(".pixel-trigger"));
        }

    }
    
};

</script>


<style lang="scss" scoped>

.pixel-trigger {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 100vh;
    left: 0;
}

</style>
