<template>

    <transition name="fade-y" mode="out-in">

        <div class="translation-status" v-if="ready">
            <span v-html="labels.played"></span>
        </div>

    </transition>

</template>


<script>

export default {

    name: 'TranslationStatus',
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            ready: false,
            labels: {
                played: '<span class="icon"></span> <span class="label">Played!</span>'
            }
        };
    },
    
    created() {
        
    },
    
    mounted() {
        this.ready = true;
    },
    
    methods: {
        
    }
    
};

</script>


<style lang="scss" scoped>

.translation-status {
    background-color: $sherpa-indicator-bg-color;
    width: 100%;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;

    & > span {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        & ::v-deep .icon {
            background-color: $sherpa-title-color-alt;
            background-image: url('../../assets/images/sherpa-speak/ss_checkmark.webp');
            background-size: 22px 22px;
            background-position: 50% 50%;
            //color: $sherpa-indicator-bg-color;
            width: 32px;
            height: 32px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            border: 6px solid $sherpa-indicator-bg-color;
            border-radius: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-6px);
        }

        & ::v-deep .label {
            font-size: 0.750rem;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            color: $sherpa-title-color-alt;
        }
    }
}

</style>
