<template>

    <div class="mastery-bg-scene">
        <div class="inner">
            <div class="figure-left"
                 data-aos="zoom-out-right"
                 :data-aos-anchor="aosAnchor"
                 data-aos-duration="2000"
                 data-aos-delay="400"
                 data-aos-easing="ease-out-quad"></div>
            <div class="figure-right"
                 data-aos="zoom-out-left"
                 :data-aos-anchor="aosAnchor"
                 data-aos-duration="2000"
                 data-aos-delay="400"
                 data-aos-easing="ease-out-quad"></div>
            <div class="cloud-left"
                 data-aos="fade-right"
                 :data-aos-anchor="aosAnchor"
                 data-aos-duration="8000"
                 data-aos-delay="800"
                 data-aos-easing="ease-out-quad"></div>
            <div class="cloud-right"
                 data-aos="fade-left"
                 :data-aos-anchor="aosAnchor"
                 data-aos-duration="8000"
                 data-aos-delay="800"
                 data-aos-easing="ease-out-quad"></div>
            <div class="cloud-base"
                 data-aos="base-cloud-up"
                 :data-aos-anchor="aosAnchor"
                 data-aos-duration="5000"
                 data-aos-delay="400"
                 data-aos-easing="ease-out-quad"></div>
        </div>
    </div>

</template>


<script>

export default {

    name: 'MasteryBgScene',

    props: {
        aosAnchor: {
            type: String,
            default: ''
        }
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
};

</script>


<style lang="scss" scoped>

.mastery-bg-scene {
    //background-color: rgba(0,121,0, 0.5);
    width: 100%;
    height: 16.250rem;
    position: absolute;
    bottom: 0;
    z-index: 1;

    & > .inner {
        width: 100%;
        max-width: 1194px;
        height: 100%;
        margin: 0 auto;
        padding: 0 0 0 0;
        position: relative;
        z-index: 2;
    }


    & .figure-left,
    & .figure-right,
    & .cloud-left,
    & .cloud-right,
    & .cloud-base {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        position: absolute;
        pointer-events: none;
    }

    & .figure-left {
        //background-color: rgba(255,51,51, 0.5);
        background-image: url('../../assets/images/sherpa-speak/ss_figure-left.webp');
        background-size: 364px 286px;
        width: 364px;
        height: 286px;
        bottom: 0;
        left: -140px;
        z-index: 5;

        @media only screen and (min-device-pixel-ratio: 1.25),
        only screen and (min-resolution: 1.25dppx) { // @2x
            background-image: url('../../assets/images/sherpa-speak/ss_figure-left_2x.webp');
            background-size: 364px 286px;
        }

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            left: -110px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            left: 0;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .figure-right {
        //background-color: rgba(88,255,51, 0.5);
        background-image: url('../../assets/images/sherpa-speak/ss_figure-right.webp');
        background-size: 458px 273px;
        width: 458px;
        height: 273px;
        bottom: 0;
        right: -110px;
        z-index: 5;

        @media only screen and (min-device-pixel-ratio: 1.25),
        only screen and (min-resolution: 1.25dppx) { // @2x
            background-image: url('../../assets/images/sherpa-speak/ss_figure-right_2x.webp');
            background-size: 458px 273px;
        }

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            right: -80px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            right: 0;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .cloud-left {
        //background-color: rgba(51,125,255, 0.5);
        background-image: url('../../assets/images/sherpa-speak/ss_cloud-left.webp');
        background-size: 573px 566px;
        width: 573px;
        height: 566px;
        top: -310px;
        left: -350px;
        z-index: 4;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            top: -310px;
            left: -210px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            top: -310px;
            left: -80px;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .cloud-right {
        //background-color: rgba(255,249,51, 0.5);
        background-image: url('../../assets/images/sherpa-speak/ss_cloud-right.webp');
        background-size: 631px 679px;
        width: 631px;
        height: 679px;
        top: -600px;
        right: -230px;
        z-index: 4;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)
            top: -600px;
            right: -230px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            top: -600px;
            right: -60px;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .cloud-base {
        //background-color: rgba(255,131,51, 0.5);
        background-image: url('../../assets/images/sherpa-speak/ss_cloud-base.webp');
        background-size: 609px 136px;
        width: 609px;
        height: 136px;
        bottom: 0;
        left: 50%;
        //transform: translateX(-50%) translateY(0);
        z-index: 6;

        @media (min-width: 576px) { // RED (SM)

        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

}

</style>
