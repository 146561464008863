<template>

    <transition name="fade" mode="out-in">

        <div class="hero" v-if="ready">

            <div class="inner">

                <div class="meta">

                    <div class="app-icon"
                         ref="icon"
                         data-aos="zoom-in-up"
                         data-aos-anchor=".hero"
                         data-aos-delay="400"
                         data-aos-duration="600"
                         data-aos-easing="ease-out-back">
                        <img :src="appIcon" :alt="titleText" />
                    </div>

                    <h1 v-html="title"
                        ref="title"
                        data-aos="zoom-in"
                        data-aos-anchor=".hero"
                        data-aos-delay="800"
                        data-aos-duration="1200"
                        data-aos-easing="ease-out-quad"></h1>

                    <hr class="bar"
                        ref="hbar"
                        data-aos="zoom-in"
                        data-aos-anchor=".hero"
                        data-aos-delay="1000"
                        data-aos-duration="600"
                        data-aos-easing="ease-out-quad" />

                    <div class="description"
                         v-html="description"
                         ref="description"
                         data-aos="fade-up"
                         data-aos-anchor=".hero"
                         data-aos-delay="1400"
                         data-aos-duration="800"
                         data-aos-easing="ease-out-quad"></div>

                    <button @click="handleScrollClick"
                            v-html="labels.scroll"
                            ref="button"
                            data-aos="fade-up"
                            data-aos-anchor=".hero"
                            data-aos-delay="1800"
                            data-aos-duration="1200"
                            data-aos-easing="ease-out-back"></button>

                </div>

                <div class="cloud-left"
                     data-aos="fade-right"
                     data-aos-anchor=".hero"
                     data-aos-delay="400"
                     data-aos-duration="7000"
                     data-aos-easing="ease-out-quad"></div>
                <div class="cloud-right"
                     data-aos-anchor=".hero"
                     data-aos="fade-left"
                     data-aos-delay="400"
                     data-aos-duration="10000"
                     data-aos-easing="ease-out-quad"></div>

            </div>

            <div class="blocker"></div>

        </div>

    </transition>

</template>


<script>

import {gsap} from 'gsap';

export default {

    name: 'Hero',
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            ready: false,
            appIcon: require('../../assets/images/sherpa-speak/ss_app-icon.webp'),
            title: '<span>Talk to</span> Your Team',
            titleText: 'Talk to Your Team',
            description: '<p>If you want to succeed on your expedition, you’re going to need a lot of help. Most climbers travel with a skilled team of guides and porters. You’ll be a more valuable team member if you learn to communicate with your teammates.</p>',
            labels: {
                scroll: '<span class="label">Scroll</span> <span class="icon"><img src="' + require('../../assets/images/sherpa-speak/ss_arrow-down.webp') + '" alt="" /></span>'
            },
            tl: null
        };
    },
    
    created() {
        
    },
    
    mounted() {
        this.$nextTick(() => {
            this.ready = true;
        });
    },
    
    methods: {

        /**
         * @method handleScrollClick
         * @description Scroll to click event handler.
         */
        handleScrollClick() {
            let target = document.querySelector('section[data-group="words"]');
            target.scrollIntoView({
                behavior: 'smooth'
            });
        },

        /**
         * @method setupAnimation
         * @description
         * @param {Object} el
         */
        // eslint-disable-next-line no-unused-vars
        setupAnimation(el) {
            // eslint-disable-next-line no-unused-vars
            const { icon, title, hbar, description, button } = this.$refs;

            gsap.set([
                icon,
                title,
                hbar,
                description,
                button
            ], {
                opacity: 0,
                y: '+=40'
            });

            gsap.set([
                icon
            ], {
                scale: 0.25
            });

            gsap.set([
                title
            ], {
                scale: 1.4
            });

            gsap.set([
                description
            ], {
                y: 0
            });


            gsap.set([
                hbar
            ], {
                scaleX: 0,
                y: 0
            });

            gsap.set([
                button
            ], {
                scale: 0.25,
                y: 0
            });

        },

        /**
         * @method enterAnimation
         * @description
         * @param {Object} el
         * @param {Function} done
         */
        enterAnimation(el, done) {

            // eslint-disable-next-line no-unused-vars
            const { icon, title, hbar, description, button } = this.$refs;

            this.tl = new gsap.timeline({
                paused: true,
                delay: 1,
                onComplete: done,
                onReverseComplete: done
            });

            this.tl.to([icon], {
                duration: 1,
                opacity: 1,
                scale: 1,
                y: 0,
                ease: 'back.out(1.7)'
            });

            this.tl.to([title], {
                duration: 1.2,
                opacity: 1,
                scale: 1,
                y: 0,
                ease: 'power2.out'
            }, '+=0.25');

            this.tl.to([
                hbar
            ], {
                duration: 0.6,
                opacity: 1,
                scaleX: 1,
                y: 0,
                ease: 'power2.out'
            }, '-=0.8');

            this.tl.to([
                description
            ], {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'power2.out'
            }, '-=0.2');

            this.tl.to([
                button
            ], {
                duration: 1,
                opacity: 1,
                y: 0,
                scale: 1,
                ease: 'back.out(1.7)'
            }, '+=0.2');

            this.tl.play();

        },

        /**
         * @method leaveAnimation
         * @description
         * @param {Object} el
         * @param {Function} done
         */
        leaveAnimation(el, done) {
            // eslint-disable-next-line no-unused-vars
            const { icon, title, hbar, description, button } = this.$refs;
            this.tl.timeScale(this.tl.duration).reverse().play();
            done();
        }

    }
    
};

</script>


<style lang="scss" scoped>

.hero {
    background-image: url('../../assets/images/sherpa-speak/ss_hero-bg.webp'),
                      linear-gradient(180deg, rgba(255,255,255,1) 52%, rgba(236,244,250,1) 100%);
    background-size: 1194px 804px, auto auto;
    background-position: 50% 0, 50% 0;
    background-repeat: no-repeat, no-repeat;
    min-height: 90vh;
    padding: 2rem 0;
    position: relative;

    @media (min-width: 576px) { // RED (SM)

    }
    @media (min-width: 768px) { // GREEN (MD)
        min-height: 70vh;
    }
    @media (min-width: 992px) { // BLUE (LG)
        min-height: 60vh;
    }
    @media (min-width: 1200px) { // YELLOW (XL)
        min-height: 90vh;
    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    & > .inner {
        width: 100%;
        max-width: 1194px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        pointer-events: none;
        z-index: 2;

        & .cloud-left,
        & .cloud-right {
            content: '';
            position: absolute;
            top: -2rem;
        }

        & .cloud-left {
            //background-color: rgba(0,121,0,0.5);
            background-image: url('../../assets/images/sherpa-speak/ss_cloud-top-left.webp');
            background-size: 477px 396px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            width: 477px;
            height: 396px;
            left: -240px;

            @media (min-width: 576px) { // RED (SM)

            }
            @media (min-width: 768px) { // GREEN (MD)
                left: -120px;
            }
            @media (min-width: 992px) { // BLUE (LG)
                left: 0;
            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .cloud-right {
            //background-color: rgba(44,121,118,0.5);
            background-image: url('../../assets/images/sherpa-speak/ss_cloud-top-right.webp');
            background-size: 376px 591px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            width: 376px;
            height: 591px;
            right: -110px;

            @media (min-width: 576px) { // RED (SM)

            }
            @media (min-width: 768px) { // GREEN (MD)
                right: -80px;
            }
            @media (min-width: 992px) { // BLUE (LG)
                right: 0;
            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }
    }

    & .blocker {
        width: 100%;
        max-width: 1194px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
    }
}

.meta {
    max-width: calc(670px + 2rem);
    margin: 0 auto;
    padding: 0 1rem;

    & .app-icon {
        margin: 4.5rem 0 9.375rem 0;
    }

    h1 {
        font-size: clamp(3.250rem, 12vw, 6.563rem);
        font-weight: 700;
        text-transform: uppercase;
        color: $sherpa-title-color-highlight;
        margin: 0 0 0 0;

        & ::v-deep span {
            font-size: clamp(1.750rem, 12vw, 3.125rem);
            font-weight: 600;
            color: $sherpa-title-color;
            display: block;
        }
    }

    .description {
        font-size: 1rem;
        font-weight: $font-weight-medium;
        line-height: 1.5;
        color: $sherpa-copy-color;
        margin: 0 0 2rem 0;
        padding: 0 0.5rem;

        & p {

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    button {
        background: linear-gradient(to right, $sherpa-button-gradient-start 0%, $sherpa-button-gradient-end 100%);
        background-clip: padding-box;
        padding: 1rem 4rem;
        border: 0 solid transparent;
        border-radius: 500px;
        box-sizing: border-box;
        box-shadow: 0 5px 16px $black-20;
        outline: none;
        overflow: hidden;
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        position: relative;
        user-select: none;
        //transition: border 0.3s $easeOutQuad 0s, transform 0.3s $easeOutQuad 0s !important;

        @media(hover: hover) {
            &:hover {
                cursor: pointer;
                transform: scale(1.02) !important;
                transition: border 0.6s $easeOutQuad 0s, transform 0.6s $easeOutQuad 0s !important;

                &::after {
                    opacity: 1;
                    transition: opacity 0.6s $easeOutQuad 0s !important;
                }

                & ::v-deep .icon {
                    transform: translateY(3px);
                    transition: transform 0.6s $easeOutQuad 0s !important;
                }
            }
        }

        &::after {
            content: '';
            background: linear-gradient(to right, $sherpa-button-gradient-start 30%, $sherpa-button-gradient-end 100%);
            width: calc(100% + 12px);
            height: 100%;
            border: 0 none;
            border-radius: 500px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translateX(-50%) translateY(-50%);
            transition: opacity 0.6s $easeOutQuad 0s !important;
        }

        &:focus,
        &:focus-within,
        &:focus-visible {
            border: 0.25rem solid rgba(64,92,155, 0.5);
            transform: scale(1.02) !important;
            transition: border 0.6s $easeOutQuad 0s, transform 0.6s $easeOutQuad 0s !important;
        }

        & ::v-deep .label {
            font-family: $base-font-family;
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $white-100;
            position: relative;
            z-index: 5;
        }

        & ::v-deep .icon {
            width: 16px;
            height: 16px;
            position: relative;
            z-index: 5;
            transition: all 0.6s $easeOutQuad 0s;

            & > img {
                width: auto;
                max-height: 100%;
            }
        }
    }
}

</style>
