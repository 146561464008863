<template>

    <audio :id="'track_' + id"
           :src="source"
           :preload="preload"
           :data-slug="slug"></audio>

</template>


<script>

import { mapActions } from 'vuex';
import { SET_PLAYED_STATE } from '../../store/sherpaSpeak/actions';

let vm = {};

export default {

    name: 'AudioPlayer',

    props: {
        id: {
            type: String,
            default: new Date().getTime().toString()
        },
        source: {
            type: String,
            default: ''
        },
        preload: {
            type: String,
            default: 'auto'
        },
        slug: {
            type: String,
            default: ''
        }
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            audioPlayer: null,
            isPlaying: false
        };
    },
    
    created() {
        
    },
    
    mounted() {
        vm = this;

        this.initAudioPlayer();
    },
    
    methods: {

        /**
         * @method initAudioPlayer
         * @description Initialize audio player & event listeners.
         */
        initAudioPlayer() {
            vm.audioPlayer = document.querySelector('#' + vm.$el.id);

            vm.audioPlayer.onplaying = (evt) => {
                let closestButton = evt.target.closest('button');
                let closestIcon = closestButton.querySelector('.icon');

                closestButton.classList.add('playing');
                closestIcon.classList.add('playing');

                vm.isPlaying = true;
            };

            vm.audioPlayer.onpause = (evt) => {
                let closestButton = evt.target.closest('button');
                let closestIcon = closestButton.querySelector('.icon');

                closestButton.classList.remove('playing');
                closestIcon.classList.remove('playing');

                vm.isPlaying = false;

                closestButton.blur();
            };

            vm.audioPlayer.onended = (evt) => {
                let closestButton = evt.target.closest('button');
                let closestIcon = closestButton.querySelector('.icon');

                closestButton.classList.remove('playing');
                closestIcon.classList.remove('playing');

                vm.isPlaying = false;

                if (evt.target.dataset.slug) {
                    vm.setPlayedState({ slug: evt.target.dataset.slug });
                }

                closestButton.blur();
            };
        },

        /**
         * @method togglePlay
         * @description
         */
        togglePlay() {
            vm.isPlaying ? vm.audioPlayer.pause() : vm.audioPlayer.play();
        },

        ...mapActions('SherpaSpeak', {
            setPlayedState: SET_PLAYED_STATE
        })

    }
    
};

</script>


<style lang="scss" scoped>

audio {
    width: 100%;
}

</style>
