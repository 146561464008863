<template>

    <div class="translation-card" :class="'translation-' + entry.slug">

        <translation :entry="entry"></translation>

        <translation-status v-if="status"
                            :status="status"></translation-status>
        
    </div>

</template>


<script>

import Translation from './Translation';
import TranslationStatus from './TranslationStatus';

export default {

    name: 'TranslationCard',

    props: {
        entry: {
            type: Object,
            default: () => {}
        },
        status: {
            type: Object,
            default: () => {}
        }
    },

    components: {
        Translation,
        TranslationStatus
    },

    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },
    
    created() {
        
    },
    
    mounted() {

    },
    
    methods: {
        
    }
    
};

</script>


<style lang="scss" scoped>

.translation-card {
    background: linear-gradient(176deg, $sherpa-card-gradient-start 40%, $sherpa-card-gradient-end 100%);
    width: 100%;
    max-width: 800px;
    padding: 2.5rem 1.25rem;
    border: 2px solid $white-30;
    border-radius: 8px;
    box-shadow: 0 3px 6px $black-10;
    overflow: hidden;
    position: relative;
}

</style>
