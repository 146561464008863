<template>

    <div class="sherpa-speak">

        <hero></hero>

        <div class="sections">

            <section-words></section-words>

            <section-phrases></section-phrases>

            <section-mastery></section-mastery>

        </div>

        <scroll-to-top></scroll-to-top>
        <pixel-trigger></pixel-trigger>

    </div>

</template>


<script>

import Hero from '@/components/sherpa-speak/Hero';
import SectionWords from '@/components/sherpa-speak/SectionWords';
import SectionPhrases from '@/components/sherpa-speak/SectionPhrases';
import SectionMastery from '@/components/sherpa-speak/SectionMastery';
import ScrollToTop from '@/components/sherpa-speak/ScrollToTop';
import PixelTrigger from '@/components/sherpa-speak/PixelTrigger';

export default {

    name: 'SherpaSpeak',

    metaInfo() {
        return {
            title: 'Talk to Your Team',
            meta: [
                {
                    name: 'description',
                    content: 'Use this interactive translator to learn some useful words and phrases spoken by Everest\'s Sherpas.'
                }, {
                    name: 'keywords',
                    content: 'national geographic, natgeo, everest, mt everest, mt. everest, mount everest, climbing, summit, summiting, explore'
                }, {
                    property: 'og:title',
                    content: 'Talk to Your Team'
                }, {
                    property: 'og:type',
                    content: 'website'
                }, {
                    property: 'og:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/talk-to-your-team.html'
                }, {
                    property: 'og:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-facebook_v01_ls_0001_TalkTeam.jpg'
                }, {
                    property: 'site_name',
                    content: 'National Geographic : Everest Educational Interactives'
                }, {
                    property: 'og:description',
                    content: 'Use this interactive translator to learn some useful words and phrases spoken by Everest\'s Sherpas.'
                }, {
                    property: 'twitter:card',
                    content: 'summary_large_image'
                }, {
                    property: 'twitter:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/talk-to-your-team.html'
                }, {
                    property: 'twitter:title',
                    content: 'Talk to Your Team'
                }, {
                    property: 'twitter:description',
                    content: 'Use this interactive translator to learn some useful words and phrases spoken by Everest\'s Sherpas.'
                }, {
                    property: 'twitter:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-twitter_2-1_v01_ls_0001_TalkTeam.jpg'
                }
            ]
        };
    },

    components: {
        Hero,
        SectionWords,
        SectionPhrases,
        SectionMastery,
        ScrollToTop,
        PixelTrigger
    },

    data() {
        return {
            componentName: this.$options.name + ' View'
        };
    },

    created() {

    },

    mounted() {

    },

    methods: {

    }

};

</script>


<style lang="scss" scoped>

.sherpa-speak {
    background-color: $sherpa-bg-color;
    overflow: hidden;
    position: relative;
}

.sections {
    padding: 0 1rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1.5rem;
}

</style>
