<template>

    <div class="translation">

        <div class="language english">
            <h4 data-aos="fade"
                :data-aos-anchor="'.translation-' + entry.slug"
                data-aos-duration="400"
                data-aos-delay="0"
                data-aos-easing="ease-out-quad">{{ labels.english }}</h4>
            <div class="phrase"
                 data-aos="zoom-in-right"
                 :data-aos-anchor="'.translation-' + entry.slug"
                 data-aos-duration="600"
                 data-aos-delay="300"
                 data-aos-easing="ease-out-back">
                <span v-html="entry.phrase.english"></span>
            </div>
        </div>

        <div class="language tibetan">
            <h4 data-aos="fade"
                :data-aos-anchor="'.translation-' + entry.slug"
                data-aos-duration="400"
                data-aos-delay="200"
                data-aos-easing="ease-out-quad">{{ labels.say }}</h4>
            <button class="phrase"
                    :data-slug="entry.slug"
                    @click.capture.stop.prevent="handlePhraseClick"
                    data-aos="zoom-in-left"
                    :data-aos-anchor="'.translation-' + entry.slug"
                    data-aos-duration="600"
                    data-aos-delay="300"
                    data-aos-easing="ease-out-back">
                <span v-html="stateIcon + ' ' + entry.phrase.tibetan"></span>
                <audio-player :id="entry.slug"
                              :source="entry.audio.tibetan"
                              :slug="entry.slug"></audio-player>
            </button>
        </div>
        
    </div>

</template>


<script>

import AudioPlayer from './AudioPlayer';

export default {

    name: 'Translation',

    props: {
        entry: {
            type: Object,
            default: () => {}
        },
        state: {
            type: String,
            default: 'ready'
        }
    },

    components: {
        AudioPlayer
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            labels: {
                english: 'English',
                say: 'Say to Your Teammates'
            }
        };
    },

    computed: {
        stateIcon() {
            return '<span class="icon ' + this.state + '"></span>';
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {

        /**
         * @method handlePhraseClick
         * @description
         * @param {Object} evt
         */
        handlePhraseClick(evt) {
            if (evt && evt.target && evt.target.childNodes[1] && (typeof evt.target.childNodes[1] === 'object')) {
                evt.target.childNodes[1].play();
            }
        }

    }
    
};

</script>


<style lang="scss" scoped>

.translation {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    position: relative;

    @media (min-width: 576px) { // RED (SM)
        flex-flow: row nowrap;
        justify-content: start;
        align-items: start;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    &::before {
        content: '';
        background-color: $sherpa-card-divider-color;
        width: 2px;
        height: calc(100% + 2rem);
        border-radius: 8px;
        overflow: hidden;
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        @media (min-width: 576px) { // RED (SM)
            display: block;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }
}

.language {
    flex: 1 0 50%;

    &.english {

        & .phrase {
            background-color: $sherpa-bubble-bg-color;
            color: $sherpa-light-blue;
            border: 2px solid $sherpa-bubble-border-color;
            border-radius: 24px 24px 24px 0;
        }
    }

    &.tibetan {

        & .phrase {
            //background: linear-gradient(5deg, rgba(251,146,25,1) 40%, rgba(246,180,103,1) 100%);
            background: linear-gradient(5deg, $sherpa-orange-gradient-start 0%, $sherpa-orange-gradient-end 100%),
                        linear-gradient(to right, $sherpa-orange 0%, $sherpa-orange 100%);
            color: $white-100;
            border: 2px solid $sherpa-orange;
            border-radius: 24px 24px 0 24px;
            position: relative;
            transform-origin: 100% 100%;
            //transform: translateZ(0) scale(1);
            //transition: transform 0.3s $easeOutQuad;
            //will-change: transform;

            @media(hover: hover) {
                &:hover {
                    cursor: pointer;
                    //transform: translateZ(0) scale(1.02);
                    //transition: transform 1.2s $easeOutBack;
                }
            }

            &:focus {

                &::after {
                    background-color: $sherpa-focus-color-orange;
                    width: calc(100% + 1rem);
                    height: calc(100% + 1rem);
                    transition: all 0.3s $easeOutQuad;
                }
            }

            &::after {
                content: '';
                background-color: transparent;
                width: 100%;
                height: 100%;
                border-radius: 30px 30px 0 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                transform: translateX(-50%) translateY(-50%);
                transition: all 0.3s $easeOutQuad;
            }

            &.playing {

                &::after {
                    background-color: $sherpa-focus-color-orange;
                    width: calc(100% + 1rem);
                    height: calc(100% + 1rem);
                    transition: all 0.3s $easeOutQuad;
                }
            }
        }
    }

    & h4 {
        font-size: 0.813rem;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: $sherpa-title-color-alt;
        margin: 0 0 1rem 0;
        user-select: none;
    }

    & button {

        &.phrase {
            padding: 0.813rem 0.75rem;
        }
    }

    & .phrase {
        padding: 1rem 1.125rem;
        border: 0 none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        //transition: all 0.6s $easeOutQuad;

        &:focus {
            outline: 6px $sherpa-focus-color-orange;
            transition: all 0.6s $easeOutQuad;
        }

        & > span {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
        }

        & > * {
            pointer-events: none;
        }

        & ::v-deep .icon {
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
            display: block;

            &.ready {
                background-image: url('../../assets/images/sherpa-speak/ss_audio-speaker.webp');

                @media only screen and (min-device-pixel-ratio: 1.25),
                only screen and (min-resolution: 1.25dppx) { // @2x
                    background-image: url('../../assets/images/sherpa-speak/ss_audio-speaker_2x.webp');
                    background-size: contain;
                }
            }

            &.playing {
                background-image: url('../../assets/images/sherpa-speak/ss_audio-bars.webp') !important;

                @media only screen and (min-device-pixel-ratio: 1.25),
                only screen and (min-resolution: 1.25dppx) { // @2x
                    background-image: url('../../assets/images/sherpa-speak/ss_audio-bars_2x.webp') !important;
                    background-size: contain;
                }
            }
        }
    }
}

</style>
