<template>

    <div class="meta">

        <hr v-if="hrule"
            class="dotted"
            data-aos="fade-up"
            :data-aos-anchor="aosAnchorClass"
            data-aos-offset="20"
            data-aos-delay="50"
            data-aos-duration="800"
            data-aos-easing="ease-out-back" />

        <h2 v-if="title"
            v-html="title"
            data-aos="fade-up"
            :data-aos-anchor="aosAnchorClass"
            data-aos-offset="20"
            data-aos-delay="150"
            data-aos-duration="800"
            data-aos-easing="ease-out-back"></h2>

        <div class="description"
             v-if="description"
             v-html="description"
             data-aos="fade-up"
             :data-aos-anchor="aosAnchorClass"
             data-aos-offset="20"
             data-aos-delay="250"
             data-aos-duration="800"
             data-aos-easing="ease-out-quad"></div>

    </div>

</template>


<script>

export default {

    name: 'SectionMeta',

    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        hrule: {
            type: Boolean,
            default: true
        },
        aosAnchorClass: {
            type: String,
            default: ''
        }
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
};

</script>


<style lang="scss" scoped>

.meta {
    max-width: 500px;
    margin: 0 auto;

    & h2 {
        font-size: 1.688rem;
        font-weight: 800;
        line-height: 1;
        text-transform: uppercase;
        color: $sherpa-title-color;
    }

    & .description {
        font-size: 0.875rem;
        line-height: 1.313;
        color: $sherpa-copy-color;
        margin: 0 0 2.5rem 0;

        & ::v-deep p {
            margin: 0 0 1.125rem 0;

            &:last-of-type {
                margin: 0 0 0 0;
            }
        }
    }
}

</style>
