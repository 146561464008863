<template>

    <section data-group="words">
    
        <div class="inner">

            <section-meta :title="title"
                          :description="description"
                          :hrule="true"></section-meta>

            <div class="translation-cards">
                <translation-card v-for="(sound, idx) in sounds"
                                  :entry="sound"
                                  :status="playedSound(sound.slug)"
                                  :key="'sound_' + idx"
                                  :data-aos="(idx % 2 === 0) ? 'fade-right' : 'fade-left'"
                                  data-aos-offset="20"
                                  data-aos-duration="800"
                                  data-aos-easing="ease-out-back"></translation-card>
            </div>

        </div>
        
    </section>

</template>


<script>

import { mapGetters } from 'vuex';

import SectionMeta from './SectionMeta';
import TranslationCard from './TranslationCard';


export default {

    name: 'SectionWords',

    components: {
        SectionMeta,
        TranslationCard
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            title: 'Get to Know Your Teammates',
            description: '<p>Sherpa names often include the day of the week the person was born. If you learn the days of the week, you might also learn a few of the names of your teammates.</p><p><strong>Listen to Glaciologist Tenzing Chogyal Sherpa and practice the Sherpa language below.</strong></p>',
            sounds: [],
            group: 'days'
        };
    },

    computed: {

        ...mapGetters('SherpaSpeak', {
            soundsByGroup: 'soundsByGroup',
            playedSound: 'playedBySlug'
        })

    },
    
    created() {
        
    },
    
    mounted() {
        this.sounds = this.soundsByGroup(this.group);
    },
    
    methods: {

        /**
         * @method getRandoFromArray
         * @description Get a random item from the array.
         * @return {*}
         */
        getRandoFromArray(arr) {
            return arr[Math.floor(Math.random() * arr.length)];
        }

    }
    
};

</script>


<style lang="scss" scoped>

section {
    margin: 2rem 0 0 0;
    padding: 1rem 0 0 0;

    & > .inner {
        width: 100%;
        height: 100%;
        position: relative;
    }

}

.translation-cards {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

</style>
